import React, { useState, useRef, useEffect } from 'react';
import { Input } from '@nextui-org/react';
import { FaMagnifyingGlass } from "react-icons/fa6";

const AddressSearch = ({ apiKey, onAddressSelect, onClearSearch }) => {
  const [inputValue, setInputValue] = useState(() => localStorage.getItem('addressInput') || '');
  const [suggestions, setSuggestions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    localStorage.setItem('addressInput', inputValue);
  }, [inputValue]);

  const handleInputChange = async (e) => {
    setInputValue(e.target.value);

    if (e.target.value.length > 3) {
      try {
        // Bounding box for Toronto and the GTA area
        const boundingBox = {
          north: 43.844078,  // Top boundary (latitude)
          south: 43.605449,  // Bottom boundary (latitude)
          east: -79.158230,  // Right boundary (longitude)
          west: -79.810929   // Left boundary (longitude)
        };

        // Fetch results from LocationIQ API
        const response = await fetch(
          `https://us1.locationiq.com/v1/autocomplete.php?key=${apiKey}&q=${e.target.value}&limit=5&countrycodes=CA&normalizecity=1&bounded=1&viewbox=${boundingBox.west},${boundingBox.north},${boundingBox.east},${boundingBox.south}`
        );
        const data = await response.json();

        // Transform LocationIQ results to match the expected structure
        const results = data.reduce((acc, result) => {
          const addressParts = result.display_name.split(','); // Split the address by commas
          const limitedAddress = [
              addressParts[0], // First part
              addressParts[1], // Second part
              addressParts[2], // Third part
              addressParts[4]  // Fifth part (skip the fourth)
          ].filter(Boolean).join(', '); // Filter to remove any undefined parts
  
          // Check against the limitedAddress instead of result.display_name
          if (!acc.some(item => item.address === limitedAddress)) {
              acc.push({
                  address: limitedAddress, // Use the limited address
                  lat: result.lat,
                  lon: result.lon,
                  isPoi: result.type === 'place',
              });
          }
          return acc;
      }, []);

        setSuggestions(results);
        setIsOpen(true);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        setIsOpen(false);
      }
    } else {
      setSuggestions([]);
      setIsOpen(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion.address);
    setSuggestions([]);
    setIsOpen(false);
    onAddressSelect({ lat: suggestion.lat, lon: suggestion.lon }, suggestion.address);
    inputRef.current?.focus();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
          inputRef.current && !inputRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div style={{ position: 'relative', marginBottom: '16px' }}>
      <Input
        classNames={{
          input: [
            "bg-transparent",
            "text-black/90 dark:text-white/90",
            "placeholder:text-default-700/50 dark:placeholder:text-white/60",
            "font-medium",
            "text-base",
            "sm:text-sm",
          ],
          innerWrapper: [
            "bg-transparent",
            "gap-0.5",
          ],
          inputWrapper: [
            "shadow-none",
            "bg-default-300/50",
            "dark:bg-default/60",
            "backdrop-saturate-200",
            "group-data-[focus=true]:bg-default-300/50",
            "dark:group-data-[focus=true]:bg-default/60",
          ],
        }}
        ref={inputRef}
        value={inputValue}
        onClear={() => {
          setInputValue(''); // Clear the input value
          setSuggestions([]); // Clear suggestions
          setIsOpen(false); // Close dropdown
          onClearSearch(); // Call the function to revert to previous state
        }}
        startContent={
          <FaMagnifyingGlass className="text-base text-default-400 pointer-events-none flex-shrink-0" />
        }
        onChange={handleInputChange}
        placeholder="Search for an address or place"
        fullWidth
        clearable
        bordered
      />
      {isOpen && suggestions.length > 0 && (
        <div 
          ref={dropdownRef}
          className='z-10 absolute flex-col items-center justify-center subpixel-antialiased outline-none box-border text-small bg-content1 rounded-large shadow-medium w-full p-4 min-w-[200px]'
        >
          {suggestions.map((suggestion, index) => (
            <div
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              className='flex flex-1 text-small font-normal truncate text-black py-2 px-2 rounded-small cursor-pointer custom-menu-item'
            >
              {suggestion.isPoi ? `${suggestion.address}` : suggestion.address}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AddressSearch;
