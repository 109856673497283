import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import RootPage from './RootPage';
import ParkingApp from './ParkingApp';
import SubwayPropertyView from './SubwayPropertyView';
import TorontoAreasMap from './TorontoAreasMap';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RootPage />} />
        <Route path="/parking" element={<ParkingApp />} />
        <Route path="/subway-property-view" element={<SubwayPropertyView />} />
        <Route path="/toronto-areas" element={<TorontoAreasMap />} />
      </Routes>
    </Router>
  );
};

export default App;
