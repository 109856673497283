import React, { useEffect, useState, useRef } from "react";
import "./App.css";
import ParkingSpots from "./ParkingSpots";
import { getUserLocation } from "./locationUtils";
import toronto2 from "./assets/toronto2.svg";
import SearchFilters from "./SearchFilters";
import { saveState } from "./localStorageUtils";
import useLocalStorageState from "./hooks/useLocalStorageState";
import MapWrapper from "./Map";
import { fetchParkingData } from "./services/parkingDataFetch";
import { loadMapProvider, saveMapProvider } from "./localStorageUtils";

const apiKey = "pk.fc6303f74e2c8dca18ffc9e583f26ba6";

const App = () => {
  const [userLocation, setUserLocation] = useLocalStorageState(
    "userLocation",
    null
  );
  const [locationActive, setLocationActive] = useLocalStorageState(
    "locationActive",
    false
  );
  const [selectedTypes, setSelectedTypes] = useLocalStorageState(
    "selectedTypes",
    new Set(["car", "bike", "evCharger"])
  );
  const [showEvCharger, setShowEvCharger] = useLocalStorageState(
    "showEvCharger",
    false
  );
  const [sortOption, setSortOption] = useLocalStorageState(
    "sortOption",
    "price"
  );
  const [selectedTime, setSelectedTime] = useLocalStorageState(
    "selectedTime",
    1
  );
  const [searchCoords, setSearchCoords] = useLocalStorageState(
    "searchCoords",
    null
  );
  const [selectedAddress, setSelectedAddress] = useLocalStorageState(
    "selectedAddress",
    null
  );
  const [showSubwayDistance, setShowSubwayDistance] = useLocalStorageState(
    "showSubwayDistance",
    true
  );
  const [parkingSpots, setParkingSpots] = useState([]); // State to store parking spots
  const [visibleSpots, setVisibleSpots] = useState([]); // State to store visible spots
  const [sortedSpots, setSortedSpots] = useState([]); // eslint-disable-line no-unused-vars
  const [headerHeight, setHeaderHeight] = useState(0);
  const headerRef = useRef(null);
  const [mapComponentRef, setMapComponentRef] = useState(null);
  const [mapProvider, setMapProvider] = useState(loadMapProvider());

  const handleMapProviderSelect = (provider) => {
    setMapProvider(provider);
    saveMapProvider(provider);
  };

  useEffect(() => {
    const header = headerRef.current;
    if (!header) return;

    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target === header) {
          setHeaderHeight(entry.target.offsetHeight);
        }
      }
    });

    resizeObserver.observe(header);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    document.title = "Parking Finder - MyToronto.City"; 
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.content = "Find the best parking spots for cars, bikes, and ev chargers in Toronto."; 
    }
  }, []);

  useEffect(() => {
    const loadParkingData = async () => {
      const data = await fetchParkingData();
      setParkingSpots(data.spots); // Set the fetched parking spots
    };
    loadParkingData();
  }, []);

  const [previousLocation, setPreviousLocation] = useState(userLocation);
  const [previousSortOption, setPreviousSortOption] = useState(sortOption);

  const handleToggleLocation = async () => {
    try {
      const location = await getUserLocation();
      setUserLocation(location);
      setLocationActive(true);
      setSearchCoords(null);
      setSelectedAddress(null);
      // Trigger map recentering
      mapComponentRef?.centerOnUserLocation(location);
    } catch (error) {
      console.error("Error getting location:", error);
      alert("Unable to retrieve location. Please check your browser settings.");
    }
  };

  const handleAddressSelect = (position, address) => {
    setPreviousLocation(userLocation);
    setPreviousSortOption(sortOption);
    setSearchCoords(position);
    setSelectedAddress(address);
    setLocationActive(false);
  };

  const handleClearSearch = () => {
    setSearchCoords(null);
    setSelectedAddress(null);
    if (previousLocation) {
      setUserLocation(previousLocation);
      setLocationActive(true);
    }
    setSortOption(previousSortOption);
  };

  const handleSortSelectionChange = (keys) => {
    const selectedSortOption = Array.from(keys)[0];
    setSortOption(selectedSortOption);
  };

  const handleTypeSelectionChange = (keys) => {
    const types = new Set(keys);
    setSelectedTypes(types);
    setShowEvCharger(types.has("evCharger"));
  };

  const handleTimeSelectionChange = (keys) => {
    setSelectedTime(parseInt(Array.from(keys)[0]));
  };

  const handleSubwayToggle = () => {
    setShowSubwayDistance((prev) => {
      const newState = !prev;
      saveState("showSubwayDistance", newState);
      return newState;
    });
  };

  useEffect(() => {
    // Set the id of the body element
    document.body.id = "container";

    // Cleanup function to remove the id when the component unmounts
    return () => {
      document.body.id = "";
    };
  }, []);

  const onMarkerClick = (lat, lng) => {
    mapComponentRef?.handleMarkerClick(lat, lng);
  };
  
  return (
    <div className="bg-whitesmoke full-viewport-height App font-geist text-lg custom-font-set2 text-zinc-900 flex flex-col h-screen">
    <header ref={headerRef} className="fixed top-0 left-0 right-0 z-50 bg-whitesmoke">
      <div className="max-w-[550px] mx-auto w-full">
                      <img
              src={toronto2}
              alt="Logo"
              className="logo mt-4 justify-center hidden sm:inline-flex"
            />
            <div className="w-full px-2 sm:px-0">
            <SearchFilters
              locationActive={locationActive}
              selectedTypes={selectedTypes}
              sortOption={sortOption}
              selectedTime={selectedTime}
              apiKey={apiKey}
              onToggleLocation={handleToggleLocation}
              onTypeSelectionChange={handleTypeSelectionChange}
              onSortSelectionChange={handleSortSelectionChange}
              onTimeSelectionChange={handleTimeSelectionChange}
              onAddressSelect={handleAddressSelect}
              onClearSearch={handleClearSearch}
              showSubwayDistance={showSubwayDistance}
              onSubwayToggle={handleSubwayToggle}
              selectedAddress={selectedAddress}
              onMapProviderSelect={handleMapProviderSelect}
              mapProvider={mapProvider}
            />
            </div>
            <div className="map-container rounded-medium overflow-hidden h-[250px] sm:h-[300px] w-full">
              <MapWrapper
                userLocation={userLocation}
                parkingSpots={parkingSpots}
                setVisibleSpots={setVisibleSpots}
                visibleSpots={visibleSpots}
                searchCoords={searchCoords}
                selectedTypes={selectedTypes}
                setMapComponentRef={setMapComponentRef}
                locationActive={locationActive}
              />
          </div>
        </div>
      </header>
      <main className="flex-grow overflow-hidden" style={{ paddingTop: `${headerHeight}px` }}>
      <div className="h-full overflow-y-scroll">
        <div className="max-w-[550px] mx-auto w-full pt-4">
            <ParkingSpots
              userLocation={locationActive ? userLocation : null}
              searchCoords={searchCoords}
              selectedTypes={selectedTypes}
              showEvCharger={showEvCharger}
              sortOption={sortOption}
              selectedTime={selectedTime}
              showSubwayDistance={showSubwayDistance}
              visibleSpots={visibleSpots}
              setSortedSpots={setSortedSpots}
              onMarkerClick={onMarkerClick}
              mapProvider={mapProvider}
              onMapProviderSelect={handleMapProviderSelect}
            />
        </div>
        </div>
      </main>
    </div>
  );
};

export default App;
