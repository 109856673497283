import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
} from "@nextui-org/react";
import { FaCircleInfo, FaCar, FaBicycle, FaBolt } from "react-icons/fa6";
import cnTower from "./assets/cnTower.png";
import cnLogo from "./assets/cn-logo.svg";
import mytonoto from "./assets/mytoronto.svg";
import subwayHousingPricing from "./assets/subwayHousingPricing.png";

const RootPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => setIsOpen(true);
  const onOpenChange = (open) => setIsOpen(open);

  const icons = [
    { component: FaCar, color: "text-orange-600", size: 24 },
    { component: FaBolt, color: "text-yellow-600", size: 23 },
    { component: FaBicycle, color: "text-emerald-700", size: 30 },
  ];

  const iconGrid = Array.from({ length: 22 }, (_, index) => {
    const IconComponent = icons[index % icons.length].component;
    const color = icons[index % icons.length].color;
    const size = icons[index % icons.length].size;
    return (
      <div key={index} className="flex justify-center items-center">
        <IconComponent size={size} className={color} />
      </div>
    );
  });

  return (
    <div className="App font-geist text-lg custom-font-set2 text-zinc-900">
      <div className="App-header">
        <div className="max-w-[550px] w-full p-4 sm:p-0 flex flex-col items-center min-h-screen">
          <img
            src={cnLogo}
            alt="Logo"
            className="logoCN mt-12 inline-flex justify-center"
          />
          <img
            src={mytonoto}
            alt="Logo"
            className="mytoronto mb-10 inline-flex justify-center"
          />

          <Card shadow="sm" isPressable className="max-w-[550px] w-full mb-4">
            <Link to="/parking">
              <CardBody className="overflow-visible p-5 flex flex-col gap-6">
                <div className="grid grid-cols-11 gap-5">{iconGrid}</div>
                <div className="flex flex-col gap-1">
                  <h2 className="text-xl font-medium">
                    Find parking in Toronto
                  </h2>
                  <p className="">
                    500+ parking spots for cars, bicycles, EV chargers.
                  </p>
                </div>
              </CardBody>
            </Link>
          </Card>

          <Card shadow="sm" isPressable={false} isDisabled={true} className="max-w-[550px] w-full mb-8">
              <CardBody className="overflow-visible p-5 flex flex-col gap-6">
                <div className="">
                  <img
                    src={subwayHousingPricing}
                    alt="Logo"
                    className="w-full rounded-lg"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <h2 className="text-xl font-medium">Subway Property View</h2>
                  <p className="">
                    Explore Toronto's subway and property prices
                  </p>
                </div>
              </CardBody>
          </Card>

          {/* <Card shadow="sm" isPressable className="max-w-[550px] w-full mb-8">
            <Link to="/toronto-areas">
              <CardBody className="overflow-visible p-5 flex flex-col gap-6">
                <div className="">
                  <img alt="Toronto Areas" className="w-full rounded-lg" />
                </div>
                <div className="flex flex-col gap-1">
                  <h2 className="text-xl font-medium">Toronto Areas Map</h2>
                  <p className="">Explore Toronto's areas and assault rates</p>
                </div>
              </CardBody>
            </Link>
          </Card> */}

          <Button
            onPress={onOpen}
            color="default"
            variant="bordered"
            className="min-w-0"
          >
            <FaCircleInfo size={16} className="text-zinc-700" />
            <p className="text-sm font-medium">About us</p>
          </Button>
          <>
            <Modal
              isOpen={isOpen}
              onOpenChange={onOpenChange}
              isDismissable={true}
              isKeyboardDismissDisabled={true}
              size="xl"
              backdrop="blur"
            >
              <ModalContent>
                {(onClose) => (
                  <>
                    <ModalHeader className="inline-flex items-center gap-3">
                      About MyToronto.City
                    </ModalHeader>
                    <ModalBody className="flex flex-col gap-6">
                      <div className="flex flex-col gap-1">
                        <p>
                          Welcome to MyToronto.City, a platform created by
                          people who live and work in Toronto, for those living
                          in or visiting the city. Our goal is to provide easy
                          access to valuable data to help you navigate life in
                          Toronto. We offer this service at no cost to help make
                          living in and enjoying Toronto easier for everyone.
                        </p>
                      </div>
                    </ModalBody>
                    <ModalFooter className="pt-0">
                      <img
                        src={cnTower}
                        alt="CN Tower"
                        className="w-36 inline-flex items-center relative bottom-[-16px] m-auto"
                      />
                    </ModalFooter>
                  </>
                )}
              </ModalContent>
            </Modal>
          </>
        </div>
      </div>
    </div>
  );
};

export default RootPage;
