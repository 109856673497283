import React, { useEffect, useRef, useState } from 'react';
import tt from '@tomtom-international/web-sdk-maps';
import '@tomtom-international/web-sdk-maps/dist/maps.css';
import { getEmojiForAssaultRate, getCenterOfFeature, createEmojiElement, applyManualAdjustment } from './areaMapUtils';

const TorontoAreasMap = () => {
  const mapElement = useRef();
  const mapInstance = useRef(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  const handleAreaSelection = (feature) => {
    console.log('handleAreaSelection called with feature:', feature);
    setSelectedArea((prevSelectedArea) => {
      const newSelectedArea = prevSelectedArea && prevSelectedArea.properties._id === feature.properties._id
        ? null
        : feature;
      console.log('New selected area:', newSelectedArea);
      return newSelectedArea;
    });
  };

  useEffect(() => {
    const initMap = () => {
      mapInstance.current = tt.map({
        key: 'UxHKXSr7GAr9zmkJSmnqbscIN6JMhlqy',
        container: mapElement.current,
        center: [-79.3832, 43.6532],
        zoom: 11,
      });

      mapInstance.current.on('load', () => {
        console.log('Map loaded');
        setMapLoaded(true);
        addAreaPolygons();
      });
    };

    const addAreaPolygons = async () => {
      if (!mapInstance.current) return;

      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/neighbourhood-crime-rates-4326.geojson`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        mapInstance.current.addSource('areas', {
          type: 'geojson',
          data: data,
        });

        mapInstance.current.addLayer({
          id: 'area-fill',
          type: 'fill',
          source: 'areas',
          paint: {
            'fill-color': 'rgba(0, 128, 255, 0.2)',
            'fill-outline-color': 'rgba(0, 128, 255, 1)',
          },
        });

        mapInstance.current.addLayer({
          id: 'area-outline',
          type: 'line',
          source: 'areas',
          paint: {
            'line-color': 'rgba(0, 128, 255, 1)',
            'line-width': 2,
          },
        });

        data.features.forEach((feature) => {
          const assaultRate = feature.properties.ASSAULT_RATE_2023;
          const emoji = getEmojiForAssaultRate(assaultRate);

          let centerCoordinates = getCenterOfFeature(feature);
          centerCoordinates = applyManualAdjustment(feature.properties.AREA_NAME, centerCoordinates);

          const markerId = `marker-${feature.properties._id}`;

          if (!document.getElementById(markerId)) {
            const markerElement = createEmojiElement(emoji);
            markerElement.id = markerId;

            new tt.Marker({ element: markerElement })
              .setLngLat(centerCoordinates)
              .addTo(mapInstance.current);
          }
        });

        mapInstance.current.on('click', 'area-fill', (e) => {
          if (e.features.length > 0) {
            const clickedFeature = e.features[0];
            console.log('Clicked feature:', clickedFeature);
            handleAreaSelection(clickedFeature);
          }
        });

        mapInstance.current.on('mouseenter', 'area-fill', () => {
          mapInstance.current.getCanvas().style.cursor = 'pointer';
        });

        mapInstance.current.on('mouseleave', 'area-fill', () => {
          mapInstance.current.getCanvas().style.cursor = '';
        });

        console.log('Area polygons added');
      } catch (error) {
        console.error('Error fetching or parsing GeoJSON:', error);
      }
    };

    initMap();

    return () => {
      if (mapInstance.current) {
        mapInstance.current.remove();
      }
    };
  }, []);

  useEffect(() => {
    console.log('selectedArea changed:', selectedArea);
    if (!mapLoaded || !mapInstance.current) {
      console.log('Map not loaded or instance not available');
      return;
    }

    if (mapInstance.current.getLayer('area-fill')) {
      const selectedId = selectedArea ? String(selectedArea.properties._id) : '';
      console.log('Updating fill color with selectedId:', selectedId);
      mapInstance.current.setPaintProperty('area-fill', 'fill-color', [
        'case',
        ['==', ['to-string', ['get', '_id']], selectedId],
        'rgba(255, 165, 0, 0.5)',
        'rgba(0, 128, 255, 0.2)',
      ]);
    } else {
      console.log('area-fill layer not found');
    }
  }, [selectedArea, mapLoaded]);

  return <div ref={mapElement} style={{ width: '100%', height: '100vh' }}></div>;
};

export default TorontoAreasMap;