import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { Spot } from "../models";
import { fetchSubwayStations } from '../subwayStations';

// Helper to fetch visible spots from Firestore
const fetchVisibleSpots = async () => {
  const spotsQuery = query(collection(db, "parkingsV2"), where("visibility", "==", true));
  const spotSnapshot = await getDocs(spotsQuery);
  return spotSnapshot.docs.map(doc => new Spot(doc.data()));
};

// Function to fetch both parking spots and subway stations with caching
export const fetchParkingData = async () => {
  const cachedData = localStorage.getItem('parkingData');
  const cachedTimestamp = localStorage.getItem('parkingDataTimestamp');
  const currentTime = new Date().getTime();

  // Check if cached data exists and is less than 24 hours old
  if (cachedData && cachedTimestamp && (currentTime - parseInt(cachedTimestamp) < 24 * 60 * 60 * 1000)) {
    return JSON.parse(cachedData);
  }

  // If no valid cached data, fetch new data
  const [fetchedSpots, stations] = await Promise.all([fetchVisibleSpots(), fetchSubwayStations()]);
  const newData = { spots: fetchedSpots, subwayStations: stations, timestamp: currentTime };

  // Cache the new data
  try {
    localStorage.setItem('parkingData', JSON.stringify(newData));
    localStorage.setItem('parkingDataTimestamp', currentTime.toString());
  } catch (error) {
    console.warn('Failed to cache parking data:', error);
  }

  return newData;
};

// Add a new function to update only the spots data
export const updateParkingSpots = async () => {
  const cachedData = localStorage.getItem('parkingData');
  if (!cachedData) {
    return fetchParkingData();
  }

  const parsedData = JSON.parse(cachedData);
  const fetchedSpots = await fetchVisibleSpots();
  parsedData.spots = fetchedSpots;
  parsedData.timestamp = new Date().getTime();

  try {
    localStorage.setItem('parkingData', JSON.stringify(parsedData));
    localStorage.setItem('parkingDataTimestamp', parsedData.timestamp.toString());
  } catch (error) {
    console.warn('Failed to update cached parking spots:', error);
  }

  return parsedData;
};