import React, { useImperativeHandle, forwardRef, useCallback, useState, useEffect, useMemo, useRef } from 'react';
import tt from '@tomtom-international/web-sdk-maps';
import { debounce } from 'lodash';
import { getIconForSpot } from './getIconForSpot';
import locationIcon from './assets/location.svg';
import searchLocationIcon from './assets/search-location.svg';

// Constants for map container style and GTA bounds
const containerStyle = {
  width: '100%',
  height: '100%',
};

const gtaBounds = {
  north: 43.9,
  south: 43.3,
  west: -79.65,
  east: -79.1,
};

const setupTileCache = (map) => {
  const tileCache = {};
  const maxCacheSize = 30;

  map.on('dataloading', (e) => {
    if (e.dataType === 'tile') {
      const tileUrl = e.url;
      const cachedTile = localStorage.getItem(tileUrl);
      if (cachedTile) {
        e.preventDefault();

        const blob = new Blob([cachedTile], { type: 'image/png' });
        const img = new Image();
        img.src = URL.createObjectURL(blob);
        img.onload = () => {
          e.target.getMap().getCanvas().getContext('2d').drawImage(img, e.coord.x, e.coord.y);

        };
      } else {

      }
    }
  });

  map.on('data', (e) => {
    if (e.dataType === 'tile' && e.tile) {
      const tileUrl = e.tile.src;
      if (!tileCache[tileUrl]) {

        fetch(tileUrl)
          .then(response => response.blob())
          .then(blob => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64data = reader.result;
              localStorage.setItem(tileUrl, base64data);
              tileCache[tileUrl] = true;

              if (Object.keys(tileCache).length > maxCacheSize) {
                const oldestKey = Object.keys(tileCache)[0];
                delete tileCache[oldestKey];
                localStorage.removeItem(oldestKey);

              }
            };
            reader.readAsDataURL(blob);
          });
      } else {

      }
    }
  });


};

const MapComponent = forwardRef(({ userLocation, parkingSpots, setVisibleSpots, searchCoords, selectedTypes, locationActive }, ref) => {
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [visibleMarkers, setVisibleMarkers] = useState([]); // eslint-disable-line no-unused-vars
  const [lastCenter, setLastCenter] = useState(null);
  const userLocationMarkerRef = useRef(null);
  const searchLocationMarkerRef = useRef(null);
  const [hasSearched, setHasSearched] = useState(false);

    // Initialize the map
  useEffect(() => {
    const map = tt.map({
      key: 'UxHKXSr7GAr9zmkJSmnqbscIN6JMhlqy',
      container: 'map',
      style: 'https://api.tomtom.com/style/1/style/22.2.1-*?map=basic_main&poi=poi_main',
      center: [userLocation?.longitude || -79.3832, userLocation?.latitude || 43.6532],
      zoom: 14,
      maxBounds: [
        [gtaBounds.west, gtaBounds.south], // Southwest coordinates
        [gtaBounds.east, gtaBounds.north]  // Northeast coordinates
      ],
      minZoom: 10,
      maxZoom: 16
    });
    setMap(map);
  
    map.on('moveend', () => {
      setLastCenter(map.getCenter());
    });

    setupTileCache(map);

    return () => map.remove();

  }, [userLocation?.latitude, userLocation?.longitude]);
 
    // Handle user location marker
    useEffect(() => {
      if (map && userLocation && locationActive) {
        const element = document.createElement('div');
        element.className = 'user-location-marker';
        const icon = document.createElement('img');
        icon.src = locationIcon;
        icon.style.width = '28px';
        icon.style.height = '28px';
        element.appendChild(icon);
  
        if (userLocationMarkerRef.current) {
          userLocationMarkerRef.current.remove();
        }
  
        userLocationMarkerRef.current = new tt.Marker({
          element: element,
          anchor: 'center',
        })
          .setLngLat([userLocation.longitude, userLocation.latitude])
          .addTo(map);
  
        map.setCenter([userLocation.longitude, userLocation.latitude]);
        map.setZoom(14);
      } else if (!locationActive && userLocationMarkerRef.current) {
        userLocationMarkerRef.current.remove();
        userLocationMarkerRef.current = null;
      }
    }, [map, userLocation, locationActive]);
  
  /**
   * Callback function to update visible spots when map bounds change
   */
  const onBoundsChanged = useCallback(() => {
    if (map) {
      const bounds = map.getBounds();
      const visible = parkingSpots.filter(spot => 
        bounds.contains([spot.lng, spot.lat]) &&
        (selectedTypes.has(spot.type) || (spot.evCharger && spot.evCharger !== "Not Available" && selectedTypes.has("evCharger")))
      );
      setVisibleSpots(visible);
      
      setVisibleMarkers(markers.filter(marker => 
        bounds.contains(marker.getLngLat()) &&
        (selectedTypes.has(marker.spot.type) || (marker.spot.evCharger && marker.spot.evCharger !== "Not Available" && selectedTypes.has("evCharger")))
      ));
    }
  }, [map, parkingSpots, setVisibleSpots, markers, selectedTypes]);

    // Debounce the onBoundsChanged function to improve performance
  const debouncedOnBoundsChanged = useMemo(() => debounce(onBoundsChanged, 300), [onBoundsChanged]);

    // Set up event listener for map movement
  useEffect(() => {
    if (map) {
      debouncedOnBoundsChanged();
      map.on('moveend', debouncedOnBoundsChanged);
      return () => {
        map.off('moveend', debouncedOnBoundsChanged);
        debouncedOnBoundsChanged.cancel();
      };
    }
  }, [map, debouncedOnBoundsChanged]);

    // Center map on user location when it changes
  useEffect(() => {
    if (map && userLocation) {
      map.setCenter([userLocation.longitude, userLocation.latitude]);
      map.setZoom(14);
    }
  }, [map, userLocation]);

    // Center map on search coordinates when they change
  useEffect(() => {
    if (map && searchCoords) {
      map.setCenter([parseFloat(searchCoords.lon), parseFloat(searchCoords.lat)]);
      map.setZoom(14);
    }
  }, [map, searchCoords]);

    // Create and update markers for parking spots
  useEffect(() => {
    if (!map) return;
  
    const newMarkers = parkingSpots.map((spot) => {
      const iconData = getIconForSpot(spot, selectedTypes);
      if (!iconData) return null;
  
      const { url, size } = iconData;
      const marker = new tt.Marker({
        element: document.createElement('div'),
        anchor: 'center',
        draggable: false,
      })
      .setLngLat([spot.lng, spot.lat])
      .addTo(map);
  
      const icon = document.createElement('img');
      icon.src = url;
      icon.style.width = `${size[0]}px`;
      icon.style.height = `${size[1]}px`;
      marker.getElement().appendChild(icon);
  
      marker.spot = spot;
  
      marker.getElement().addEventListener('click', () => {
        map.setCenter(marker.getLngLat());
        map.setZoom(16);
      });
  
      return marker;
    }).filter(marker => marker !== null); 
  
    setMarkers(newMarkers);
  
    const bounds = map.getBounds();
    setVisibleMarkers(newMarkers.filter(marker => 
      bounds.contains(marker.getLngLat())
    ));
  
    return () => {
      newMarkers.forEach(marker => marker.remove());
    };
  }, [map, parkingSpots, selectedTypes]);

    // Expose methods to parent component
  useImperativeHandle(ref, () => ({
    handleMarkerClick: (lat, lng) => {
      if (map) {
        map.setCenter([lng, lat]);
        map.setZoom(16);
      }
    },
    centerOnUserLocation: (location) => {
      if (map && location) {
        map.setCenter([location.longitude, location.latitude]);
        map.setZoom(14);
      }
    }
  }));

    // Handle search location marker and map centering
useEffect(() => {
  if (map && searchCoords) {
    const newCenter = [parseFloat(searchCoords.lon), parseFloat(searchCoords.lat)];
    
    if (!hasSearched) {
      map.setCenter(newCenter);
      map.setZoom(15);
      setLastCenter({ lng: newCenter[0], lat: newCenter[1] });
      setHasSearched(true);
    }

    if (searchLocationMarkerRef.current) {
      searchLocationMarkerRef.current.setLngLat(newCenter);
    } else {
      const element = document.createElement('div');
      element.className = 'search-location-marker';
      const icon = document.createElement('img');
      icon.src = searchLocationIcon;
      icon.style.width = '40px';
      icon.style.height = '40px';
      element.appendChild(icon);

      const marker = new tt.Marker({
        element: element,
        anchor: 'bottom',
      })
        .setLngLat(newCenter)
        .addTo(map);

      searchLocationMarkerRef.current = marker;
    }
  } else if (searchLocationMarkerRef.current && !searchCoords) {
    searchLocationMarkerRef.current.remove();
    searchLocationMarkerRef.current = null;
    setHasSearched(false);
    if (lastCenter) {
      map.setCenter([lastCenter.lng, lastCenter.lat]);
    }
  }
}, [map, searchCoords, lastCenter, hasSearched]);

  return (
    <div id="map" style={containerStyle}></div>
  );
});

/**
 * MapWrapper - Wrapper component for MapComponent
 * 
 * This component passes props to MapComponent and handles the ref
 */
const MapWrapper = ({ userLocation, parkingSpots, setVisibleSpots, visibleSpots, searchCoords, selectedTypes, setMapComponentRef, locationActive }) => {
  return (
    <MapComponent
      ref={setMapComponentRef} 
      userLocation={userLocation} 
      parkingSpots={parkingSpots} 
      setVisibleSpots={setVisibleSpots} 
      visibleSpots={visibleSpots}
      searchCoords={searchCoords}
      selectedTypes={selectedTypes}
      locationActive={locationActive}
    />
  );
};

export default React.memo(MapWrapper);