import React, { memo, useState } from "react";
import { Card, CardBody, Button } from "@nextui-org/react";
import { calculateDistance } from "./locationUtils";
import {
  FaCar,
  FaBicycle,
  FaBolt,
  FaPersonWalking,
  FaTrainSubway,
} from "react-icons/fa6";
import { calculateCostBasedOnTime } from "./utils";
import { calculateWalkingAndBikingTime } from "./calculateWalkingAndBikingTime";
import googleMapsIcon from "./assets/googlemaps.png";
import appleMapsIcon from "./assets/applemaps.webp";
import MapProviderModal from "./MapProviderModal";

const ParkingSpotCard = memo(
  ({
    spot,
    selectedTime,
    showSubwayDistance,
    userLocation,
    selectedTypes,
    onMarkerClick,
    mapProvider,
    onMapProviderSelect,
  }) => {
    const renderDistance = () => {
      if (userLocation) {
        const distance = calculateDistance(
          userLocation.latitude,
          userLocation.longitude,
          spot.lat,
          spot.lng
        );
        return (
          <span className="text-gray-600">{distance.toFixed(1)}km to </span>
        );
      }
      return null;
    };

    const renderIcons = () => {
      const icons = [];
      if (spot.type === "car" && selectedTypes.has("car"))
        icons.push(
          <FaCar className="text-gray-700" key="car" size={16.5} />
        );
      if (spot.type === "bike" && selectedTypes.has("bike"))
        icons.push(
          <FaBicycle className="text-gray-700" key="bike" size={20} />
        );
      if (
        spot.type !== "bike" &&
        spot.bikeRacks &&
        spot.bikeRacks !== "not available" &&
        selectedTypes.has("bike")
      ) {
        icons.push(
          <FaBicycle className="text-gray-700" key="bikeRacks" size={20} />
        );
      }
      if (
        spot.evCharger &&
        spot.evCharger !== "Not Available" &&
        selectedTypes.has("evCharger")
      ) {
        icons.push(
          <FaBolt size={15} className="text-gray-700" key="evCharger" />
        );
      }
      return icons;
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleMapProviderSelect = (provider) => {
      onMapProviderSelect(provider); 
      setIsModalOpen(false);
    };

    const openMap = (lat, lng) => {
      if (!mapProvider) {
        setIsModalOpen(true);
        return;
      }
      const url =
        mapProvider === "google"
          ? `https://www.google.com/maps?q=${lat},${lng}`
          : `https://maps.apple.com/?q=${lat},${lng}`;
      // window.open(url, "_blank");
      window.location.href = url;
    };

    return (
      <>
      <Card
        shadow="sm"
        isPressable
        as="div"
        onPress={() => onMarkerClick(spot.lat, spot.lng)}
        className="parking-spot-card"
      >
        <CardBody className="overflow-visible p-5">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col items-start">
              <div className="flex flex-col items-start gap-1">
                <div className="inline-flex items-center gap-2">
                  {renderIcons()}
                </div>
                <div className="inline-flex items-center gap-1">
                  <p>
                    {renderDistance()}
                    {spot.street}
                  </p>
                </div>
              </div>

              <div className="inline-flex">
              {showSubwayDistance && spot.nearestStation && (
                <div className="inline-flex items-center gap-2 gap-y-0.5 text-base mt-2 text-gray-600 flex-wrap">
                  <div className="inline-flex items-center gap-1">
                    <FaPersonWalking size={16} className="" />{" "}
                    {Math.round(
                      calculateWalkingAndBikingTime(spot.distanceToStation)
                        .walkingTimeMinutes
                    )}{" "}
                    min
                  </div>

                  {calculateWalkingAndBikingTime(spot.distanceToStation)
                    .walkingTimeMinutes > 30 && (
                    <div className="inline-flex items-center gap-1">
                      {" / "}
                      <FaBicycle size={19} className="" />{" "}
                      {Math.round(
                        calculateWalkingAndBikingTime(
                          spot.distanceToStation
                        ).bikingTimeMinutes
                      )}{" "}
                      min
                    </div>
                  )}

                  <div className="inline-flex items-center gap-1.5">
                    <p>to</p>
                    <FaTrainSubway size={15} className="" />
                    {spot.nearestStation.Station}
                  </div>
                </div>
              )}

</div>
            </div>
            <div className="flex flex-col justify-between min-w-20 max-w-[160px] text-base text-right">
              <p>{calculateCostBasedOnTime(spot, selectedTime)}</p>

              {mapProvider ? (
                  <div
                  className="mt-2 cursor-pointer flex justify-end mr-[-16px] pr-[16px] pt-[16px] pb-[16px] mb-[-16px] grayscale hover:grayscale-0"
                  onClick={() => openMap(spot.lat, spot.lng)}
                >
                  <img
                    src={mapProvider === "google" ? googleMapsIcon : appleMapsIcon}
                    alt={`${mapProvider} Maps`}
                    style={{ width: "26px", height: "26px" }}
                    className=""
                  />
                </div>
                ) : (
                  <Button 
                  onClick={() => setIsModalOpen(true)}
                  className="h-7 border-default bg-transparent border-medium"
                  >
                    Open in...
                  </Button>
                )}
            </div>
          </div>

        </CardBody>
      </Card>

      <MapProviderModal // Use the new modal component
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onMapProviderSelect={handleMapProviderSelect}
      />
</>
    );
  }
);

export default ParkingSpotCard;
