import React, { useState, useEffect } from 'react';
import { DndContext } from '@dnd-kit/core';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import torontoMap from './assets/torontomap.webp';

function DraggableMap({ transform, setTransform }) {
  const { attributes, listeners, setNodeRef, transform: dragTransform } = useDraggable({
    id: 'toronto-map',
  });

  const style = dragTransform
    ? {
        transform: CSS.Translate.toString({
          x: transform.x + dragTransform.x,
          y: transform.y + dragTransform.y,
        }),
      }
    : {
        transform: CSS.Translate.toString(transform),
      };

  return (
    <img
      ref={setNodeRef}
      src={torontoMap}
      alt="Toronto Map"
      loading="lazy"
      {...listeners}
      {...attributes}
      style={style}
      className="fixed-size cursor-move"
    />
  );
}

const SubwayPropertyView = () => {
  const [transform, setTransform] = useState({ x: 0, y: 0 });

  const handleDragEnd = (event) => {
    const { delta } = event;
    setTransform((prev) => ({
      x: prev.x + delta.x,
      y: prev.y + delta.y,
    }));
  };

  useEffect(() => {
    // Disable scrolling
    document.body.style.overflow = 'hidden';
    return () => {
      // Re-enable scrolling when component unmounts
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <div className="subway-property-view h-screen w-screen overflow-hidden bg-[#F1EAD8]">
        <div className='relative z-10 backdrop-blur-md p-4 rounded-md w-fit'>
            <h1 className="text-2xl font-bold">Subway Property View</h1>
        </div>
        <div className="map-container h-full w-full">
          <DraggableMap transform={transform} setTransform={setTransform} />
        </div>
      </div>
    </DndContext>
  );
};

export default SubwayPropertyView;