export class Spot {
  constructor(data) {
    this.id = data.id || "";
    this.originalId = data.originalId || null;
    this.lat = data.lat || null;
    this.lng = data.lng || null;
    this.visibility = data.visibility || false;
    this.bikeRacks = data.bikeRacks || "";
    this.earlyBirdTo = data.earlyBirdTo || "";
    this.earlyBird = data.earlyBird || false;
    this.twentyMin = data.twentyMin || data.twenty_min || null;
    this.thirtyMin = data.thirtyMin || null;
    this.oneHour = data.oneHour || null;
    this.street = data.street || "";
    this.evCharger = data.evCharger || "";
    this.capacity = data.capacity || "";
    this.type = data.type || "";
    this.provider = data.provider || "";
    this.days = data.days || "";
    this.from = data.from || "";
    this.to = data.to || "";
    this.rate = data.rate || "";
    this.freeParking = data.freeParking || false;
    this.freeParkingTime1 = data.freeParkingTime1 || "";
    this.freeParkingTime2 = data.freeParkingTime2 || "";
    this.noParkingTime1 = data.noParkingTime1 || "";
    this.noParkingTime2 = data.noParkingTime2 || "";
    this.noParking = data.noParking || false;

    // Correctly parse the ratesContainer
    if (data.ratesContainer && data.ratesContainer.rates) {
      this.ratesContainer = data.ratesContainer.rates.map(rateData => new Rates(rateData));
    } else {
      this.ratesContainer = [];
    }
  }
}


export class Rates {
  constructor(data) {
    this.days = Array.isArray(data.Days) ? data.Days : [];
    this.earlyBird = data.EarlyBird || false;
    this.earlyBirdTo = data.EarlyBirdTo || "";
    this.freeParking = data.FreeParking || false;
    this.from = data.From || "";
    this.noParking = data.NoParking || false;
    this.rate = data.Rate || "";
    this.rateType = data.RateType || "";
    this.to = data.To || "";
  }
}
