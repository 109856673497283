// src/hooks/useLocalStorageState.js
import { useState, useEffect } from 'react';
import { loadState, saveState } from '../localStorageUtils';

const useLocalStorageState = (key, defaultValue) => {
  const [state, setState] = useState(() => {
    const savedState = loadState(key, defaultValue);
    // If the default value is a Set, convert the saved state back to a Set
    if (defaultValue instanceof Set) {
      return new Set(savedState);  // Convert back to a Set
    }
    return savedState;
  });

  useEffect(() => {
    // Ensure Set is serialized properly
    const stateToSave = state instanceof Set ? Array.from(state) : state;
    saveState(key, stateToSave);
  }, [key, state]);

  return [state, setState];
};

export default useLocalStorageState;
