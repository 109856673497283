import React from "react";
import { Link } from "react-router-dom";
import {
  FaLocationArrow,
  FaCar,
  FaBicycle,
  FaBolt,
  FaGear,
  FaCircleInfo,
  FaChevronLeft
} from "react-icons/fa6";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  DropdownSection,
  Button,
  Switch,
  useDisclosure,
} from "@nextui-org/react";
import AddressSearch from "./AddressSearch";
import AboutModal from "./AboutModal";
import MapProviderModal from "./MapProviderModal";
function SearchFilters({
  locationActive,
  selectedTypes,
  selectedTime,
  apiKey,
  onToggleLocation,
  onTypeSelectionChange,
  onTimeSelectionChange,
  onAddressSelect,
  onClearSearch,
  onSubwayToggle,
  showSubwayDistance,
  selectedAddress,
  onMapProviderSelect,
  mapProvider,
}) {

  const selectedIcons = React.useMemo(() => {
    const icons = [];
    selectedTypes.forEach((type) => {
      switch (type) {
        case "car":
          icons.push(
            <FaCar size={16.5} key="car" className=" text-[#e6891e]" />
          );
          break;
        case "bike":
          icons.push(
            <FaBicycle
              size={20}
              key="bike"
              className=" text-[#159ab0] mr-1"
            />
          );
          break;
        case "evCharger":
          icons.push(
            <FaBolt size={15} key="evCharger" className=" text-[#39BF5B]" />
          );
          break;
        default:
          break;
      }
    });
    return icons;
  }, [selectedTypes]);

  const { isOpen, onOpen, onOpenChange } = useDisclosure(); 
  const [isMapProviderModalOpen, setIsMapProviderModalOpen] = React.useState(false);
  const mapProviderDisplayName = mapProvider === "google" 
    ? "Google Maps" 
    : mapProvider === "apple" 
    ? "Apple Maps" 
    : "Select";

  return (
    <div className="flex flex-col">
      <div className="inline-flex gap-1 sm:gap-2 py-4 justify-center">
      <Button
          as={Link}
          to="/" 
          color="default"
          variant="bordered"
          className="min-w-6 px-2 text-zinc-700" 
        >
          <FaChevronLeft size={16} /> 
        </Button>
        <Button
          onClick={onToggleLocation}
          color="default"
          variant="bordered"
          className="min-w-0"
        >
          <FaLocationArrow
            color={locationActive ? "#3b82f6" : "grey"}
            size={18}
          />
        </Button>

        <Dropdown>
          <DropdownTrigger>
            <Button variant="bordered" className="capitalize font-medium">
              {selectedIcons}
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            aria-label="Filter by Type"
            variant="flat"
            closeOnSelect={false}
            disallowEmptySelection
            selectionMode="multiple"
            selectedKeys={selectedTypes}
            onSelectionChange={onTypeSelectionChange}
          >
            <DropdownSection title="Filter by type">
              <DropdownItem key="car" textValue="Car">
                {" "}
                <div className="inline-flex items-center">
                  <FaCar
                    size={16.5}
                    key="car"
                    className=" text-[#e6891e] mr-3"
                  />{" "}
                  Car{" "}
                </div>
              </DropdownItem>
              <DropdownItem key="bike" textValue="Bike">
                <div className="inline-flex items-center">
                  <FaBicycle
                    size={19}
                    key="bike"
                    className=" text-[#159ab0] mr-2"
                  />{" "}
                  Bike{" "}
                </div>
              </DropdownItem>
              <DropdownItem key="evCharger" textValue="EV Charger">
                <div className="inline-flex items-center">
                  <FaBolt
                    size={15}
                    key="evCharger"
                    className=" text-[#39BF5B] mr-2 w-5"
                  />{" "}
                  EV Charger{" "}
                </div>
              </DropdownItem>
            </DropdownSection>
          </DropdownMenu>
        </Dropdown>

        <Dropdown>
          <DropdownTrigger>
            <Button variant="bordered" className="font-medium min-w-0">
              <div
                className={`circle sm:block hidden ${
                  selectedTime === 1
                    ? "one-hour"
                    : selectedTime === 2
                    ? "two-hours"
                    : "four-hours"
                }`}
              ></div>
              {selectedTime}h
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            aria-label="Select time"
            variant="flat"
            closeOnSelect
            selectionMode="single"
            selectedKeys={new Set([String(selectedTime)])}
            onSelectionChange={onTimeSelectionChange}
          >
            <DropdownSection title="Parking duration">
              <DropdownItem key="1" textValue="One hour">
                <div className="inline-flex items-center gap-1.5">
                  <div className="circle one-hour"></div>
                  <label>1 hour</label>
                </div>
              </DropdownItem>
              <DropdownItem key="2" textValue="Two hours">
                <div className="inline-flex items-center gap-1.5">
                  <div className="circle two-hours"></div>
                  <label>2 hours</label>
                </div>
              </DropdownItem>
              <DropdownItem key="4" textValue="Four hours">
                <div className="inline-flex items-center gap-1.5">
                  <div className="circle four-hours"></div>
                  <label>4 hours</label>
                </div>
              </DropdownItem>
            </DropdownSection>
          </DropdownMenu>
        </Dropdown>

        <Dropdown>
          <DropdownTrigger>
            <Button
              variant="bordered"
              className="capitalize font-medium min-w-0"
            >
              <FaGear size={16} className="text-zinc-700" />
            </Button>
          </DropdownTrigger>
          <DropdownMenu className="">
            <DropdownItem isReadOnly showDivider key="1" textValue="Distance to Subway">
              <div className="inline-flex items-center gap-8 w-full justify-between">
                <div className="flex flex-col items-start max-w-64">
                  <label className="font-semibold mb-0.5">
                    Distance to Subway
                  </label>
                  <label className="text-balance whitespace-normal text-zinc-600">
                    Shows the distance from the parking spot to the nearest
                    subway
                  </label>
                </div>
                <Switch
                  isSelected={showSubwayDistance}
                  onChange={onSubwayToggle}
                />
              </div>
            </DropdownItem>
            <DropdownItem key="2" textValue="Select Map Provider" onClick={() => setIsMapProviderModalOpen(true)}>
              <div className="inline-flex items-center gap-8 w-full justify-between cursor-pointer">
                <label className="font-semibold mb-0.5 cursor-pointer">
                Map Provider
                </label>
                <label className="text-zinc-600 cursor-pointer">
                  {mapProviderDisplayName}
                </label>
              </div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Button
          onPress={onOpen}
          color="default"
          variant="bordered"
          className="min-w-0"
        >
          <FaCircleInfo size={16} className="text-zinc-700" />
        </Button>
      </div>

      <AddressSearch
        apiKey={apiKey}
        onAddressSelect={onAddressSelect}
        onClearSearch={onClearSearch}
      />

      {selectedAddress && (
        <p className="text-left mb-2 text-base font-medium">
          <p className="truncate">Locations near: {selectedAddress}</p>
        </p>
      )}

      <AboutModal isOpen={isOpen} onOpenChange={onOpenChange} />
      <MapProviderModal
        isOpen={isMapProviderModalOpen}
        onClose={() => setIsMapProviderModalOpen(false)}
        onMapProviderSelect={onMapProviderSelect}
      />
    </div>
  );
}

export default SearchFilters;
